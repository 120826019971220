import React, { useState, useEffect } from 'react'
import '../styles/schedulePage.css'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  scheduleStatusSourceUrl,
  scheduleSourceUrl
} from '../params/constants'
import ru from 'date-fns/locale/ru'
import axios from 'axios'
import ServerAlerts from '../Components/ServerAlerts'
import RenderChapters from '../Components/Mailing/RenderChapters'
import {
  dateToYYYYMMDD,
  datesRangeToReadableString,
  guessMonthByDatesRange
} from '../utils/Formatters/dateFormatters'
import Button from '@mui/material/Button'
import { ServerDto } from '../types/DTO/Server.dto'
import { UlEventRaw, UlEvent } from '../types/DTO/UlEvent.dto'
import { processFetchedEvent } from '../utils/DataGetters/fetchSinglePage'
import { updateLS } from '../utils/LS/storage'
import RenderHtmlFile from '../utils/RenderFiles/Mailing/RenderHtmlFile'
import renderHtmlScheduleDOCs from '../utils/RenderFiles/renderHtmlScheduleDOCs'
import renderCSV from '../utils/RenderFiles/renderCSV'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Menu, MenuItem } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { Notify } from '../types/types'
import CircularProgress from '@mui/material/CircularProgress'
import { ScheduleType, scheduleTypes } from '../params/schedule'
import { Chapter, chapters } from '../params/mailing'
import ModalCertGenerator from '../Components/Modals/ModalCertGenerator'
import { mapKeyToTemplate } from '../params/certTemplates'

registerLocale('ru', ru)

export interface MailPropsBase {
  notify: Notify
  showDescriptions: boolean
  eventsDTO: UlEvent[]
  setEventsDTO: (arg: UlEvent[]) => void
  scheduleType: ScheduleType
  inputError: boolean
  setInputError: (arg: boolean) => void
}

export interface MailProps extends MailPropsBase {
  globalIndex: number
  chapterIndex: number
  chapterId: Chapter
  chapterLength: number
}

const initialServerDto: ServerDto = {
  updatedHrsAgo: null
}

interface SchedulePageProps {
  notify: Notify
}

const MailingPage = ({ notify }: SchedulePageProps): JSX.Element => {
  const [showDescriptions, setShowDescriptions] = useState<boolean>(false)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(startDate)
  const [statusDTO, setStatusDTO] = useState(initialServerDto)
  const [anchorElDOC, setAnchorElDOC] = React.useState<null | HTMLElement>(
    null
  )
  const [anchorElHTML, setAnchorElHTML] = React.useState<null | HTMLElement>(
    null
  )
  const [eventsDTO, setEventsDTO] = useState<UlEvent[]>([])
  const [inputError, setInputError] = useState<boolean>(
    eventsDTO.some((event) => event.isUnfetched === true)
  )
  const [fetching, setFetching] = useState<boolean>(false)
  const [scheduleType, setScheduleType] = useState<ScheduleType>(
    ScheduleType.Weekly
  )
  const [doRenderMailingHTML, setDoRenderMailingHTML] =
    useState<boolean>(false)
  const [renderMailingHTMLProps, setRenderMailingHTMLProps] = useState({
    events: eventsDTO,
    scheduleType: ScheduleType.Weekly,
    dates: ''
  })

  const [certTemplate, setCertTemplate] = useState<string>('')

  const openMenuDOC = Boolean(anchorElDOC)
  const openMenuHTML = Boolean(anchorElHTML)

  useEffect(() => {
    const getUpdatedHrsAgo = async (): Promise<void> => {
      const result = await axios.get(scheduleStatusSourceUrl)
      if (result.status === 200) {
        setStatusDTO(result.data)
      }
    }

    getUpdatedHrsAgo().catch((error) => {
      console.log(error)
    })
  }, [])

  useEffect(() => {
    setInputError(
      eventsDTO.some(
        (event) =>
          event.isUnfetched === true ||
          !chapters
            .filter((chapter) => chapter.id === event.chapterId)[0]
            .availableEventTypes.includes(event.eventType)
      )
    )
  }, [eventsDTO])

  const onChange = (dates: any): void => {
    const [start, end] = dates

    setStartDate(start)
    setEndDate(end)
  }

  const serverAlertProps = {
    statusDTO,
    setStatusDTO,
    scheduleType,
    setScheduleType
  }

  const handleClose = (): void => {
    setAnchorElDOC(null)
    setAnchorElHTML(null)
  }

  const handleShowDescriptions = (): void => {
    setShowDescriptions(!showDescriptions)
  }

  const getAllEvents = async (): Promise<void> => {
    setFetching(true)
    const formattedStartDate = dateToYYYYMMDD(startDate, '-')
    const formattedEndDate = dateToYYYYMMDD(
      endDate === null ? startDate : endDate,
      '-'
    )
    const fetchUrl: string = `${scheduleSourceUrl}?dates=${formattedStartDate}--${formattedEndDate}&group`
    const result = await axios.get(fetchUrl).catch((err) => {
      console.log(err)
      return { data: [] }
    })
    const processedUlEvents = result.data.data.map((event: UlEventRaw) =>
      processFetchedEvent(event)
    )
    setEventsDTO(processedUlEvents)
    setFetching(false)
  }

  const handleRenderDOC = async (mode: ScheduleType): Promise<void> => {
    const endDateCalc = endDate ?? startDate

    const formattedStartDate = dateToYYYYMMDD(startDate, '-')
    const formattedEndDate = dateToYYYYMMDD(endDateCalc, '-')

    // const fetchUrl: string = {
    //   [ScheduleType.Monthly]: `${scheduleSourceUrl}?dates=${formattedStartDate}--${formattedEndDate}&noDescr&group`,
    //   [ScheduleType.Weekly]: `${scheduleSourceUrl}?dates=${formattedStartDate}--${formattedEndDate}&noDescr&eventTypes=excursion,webinar,lecture,event&group`,
    //   [ScheduleType.WeekEnd]: `${scheduleSourceUrl}?dates=${formattedStartDate}--${formattedEndDate}&noDescr&eventTypes=excursion,webinar,lecture,event&group`
    // }[mode]

    renderHtmlScheduleDOCs(eventsDTO, {
      scheduleType: mode,
      dates:
        mode === ScheduleType.Monthly
          ? guessMonthByDatesRange(startDate, endDateCalc)
          : datesRangeToReadableString(startDate, endDateCalc)
    })
  }

  const handleClickRenderDOC = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    setAnchorElDOC(event.currentTarget)
  }

  const handleClickRenderHTML = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    setAnchorElHTML(event.currentTarget)
  }

  const handleRenderCSV = async (): Promise<void> => {
    const formattedStartDate = dateToYYYYMMDD(startDate, '-')
    const formattedEndDate = dateToYYYYMMDD(
      endDate === null ? startDate : endDate,
      '-'
    )

    const fetchUrl: string = `${scheduleSourceUrl}?dates=${formattedStartDate}--${formattedEndDate}&noDescr&group`
    const result = await axios.get(fetchUrl)

    renderCSV(result.data.data, startDate, endDate)
  }

  const handleRendertHTML = (mode: ScheduleType): void => {
    updateLS(eventsDTO)

    setRenderMailingHTMLProps({
      events: eventsDTO,
      scheduleType: mode,
      dates:
        mode === ScheduleType.Monthly
          ? guessMonthByDatesRange(startDate, endDate)
          : datesRangeToReadableString(startDate, endDate)
    })

    setDoRenderMailingHTML(true)
  }

  const renderProps: MailPropsBase = {
    notify,
    showDescriptions,
    eventsDTO,
    setEventsDTO,
    scheduleType,
    inputError,
    setInputError
  }

  return doRenderMailingHTML
    ? (
    <RenderHtmlFile {...renderMailingHTMLProps} />
      )
    : (
    <>
      {certTemplate && (
        <ModalCertGenerator
          template={certTemplate}
          setShow={setCertTemplate}
          show={Boolean(certTemplate)}
        />
      )}

      <ServerAlerts {...serverAlertProps} />
      <DatePicker
        minDate={new Date()}
        locale="ru"
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        inline
      />

      <div style={{ marginTop: '2rem' }}>
        <div className="description">
          <h2>МЕРОПРИЯТИЯ</h2>
          Загрузить список мероприятий на выбранные даты.
          <div style={{ display: 'flex', flexFlow: 'row nowrap' }}>
            <Button
              style={{
                display: 'block',
                marginTop: '1rem',
                marginBottom: '1rem'
              }}
              onClick={getAllEvents}
              variant="contained"
              disabled={fetching}
            >
              Загрузить
            </Button>

            {fetching && (
              <CircularProgress
                size={24}
                sx={{
                  marginTop: '1.3rem',
                  marginLeft: '0.5rem'
                }}
              />
            )}
          </div>
          {eventsDTO.length > 0 && (
            <>
              <FormControlLabel
                control={<Switch onChange={handleShowDescriptions} />}
                label="Показывать исправленные описания"
              />
              <RenderChapters {...renderProps} />{' '}
            </>
          )}
        </div>
        <div className="description">
          <h2>РАССЫЛКА</h2>
          <p>
            Вывести HTML файл под выбранный шаблон для рассылки в SendPulse.
          </p>
          <Button
            sx={{ mr: 1 }}
            onClick={handleClickRenderHTML}
            disabled={eventsDTO.length === 0 || inputError}
            variant="contained"
            endIcon={<KeyboardArrowDownIcon />}
          >
            Экспорт HTML
          </Button>
          <Menu
            id="render-html-mode"
            anchorEl={anchorElHTML}
            open={openMenuHTML}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem
              onClick={() => {
                handleRendertHTML(ScheduleType.WeekEnd)
              }}
            >
              {scheduleTypes[ScheduleType.WeekEnd].name}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleRendertHTML(ScheduleType.Weekly)
              }}
            >
              {scheduleTypes[ScheduleType.Weekly].name}
            </MenuItem>
          </Menu>
        </div>

        <div className="description">
          <h2>РАСПИСАНИЕ</h2>
          <p>
            Вывести 2 DOC-файла с мероприятиями (1 файл со ссылками, 1 без) под
            выбранный шаблон.
          </p>
          <Button
            sx={{ mr: 1 }}
            disabled={eventsDTO.length === 0 || inputError}
            onClick={handleClickRenderDOC}
            variant="contained"
            endIcon={<KeyboardArrowDownIcon />}
          >
            Экспорт DOC
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorElDOC}
            open={openMenuDOC}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem
              onClick={() => {
                handleRenderDOC(ScheduleType.WeekEnd).catch((e) => {})
              }}
            >
              {scheduleTypes[ScheduleType.WeekEnd].name}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleRenderDOC(ScheduleType.Weekly).catch((e) => {})
              }}
            >
              {scheduleTypes[ScheduleType.Weekly].name}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleRenderDOC(ScheduleType.Monthly).catch((e) => {})
              }}
            >
              {scheduleTypes[ScheduleType.Monthly].name}
            </MenuItem>
          </Menu>
        </div>

        <div className="description">
          <h2>СЕРТИФИКАТЫ</h2>
          <img
            src={mapKeyToTemplate.REGULAR.thumb}
            onClick={() => {
              setCertTemplate('REGULAR')
            }}
            style={{ cursor: 'pointer', margin: 10, border: '1px solid grey' }}
          />
          <img
            src={mapKeyToTemplate.BIRTHDAY.thumb}
            onClick={() => {
              setCertTemplate('BIRTHDAY')
            }}
            style={{ cursor: 'pointer', margin: 10, border: '1px solid grey' }}
          />
          <img
            src={mapKeyToTemplate.NEWYEAR.thumb}
            onClick={() => {
              setCertTemplate('NEWYEAR')
            }}
            style={{ cursor: 'pointer', margin: 10, border: '1px solid grey' }}
          />
        </div>
        <div className="description">
          <h2>РАСКЛАДУШКА</h2>
          <p>
            CSV файл можно загрузить в Google Sheets и получить почти готовую
            таблицу мероприятий для раскладушки. В таблице дополнительно
            понадобится:
          </p>
          <ul>
            <li>Отметить новинки</li>
            <li>
              Разделить экскурсии на просто «Экскурсии», «Пешеходные экскурсии»,
              «Однодневные путешествия» и т.д.
            </li>
            <li>Проставить тэги</li>
          </ul>

          <Button sx={{ m: 1 }} onClick={handleRenderCSV} variant="contained">
            Экспорт CSV
          </Button>
        </div>
      </div>
    </>
      )
}

export default MailingPage

import React, { useEffect, useState } from 'react'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import { brToRn, rnToBr } from '../../utils/Formatters/textFormatters'
import { MailProps } from '../../Pages/MailingPage'
import PremiereCheckBox from './Event/PremiereCheckBox'
import SetEventType from './Event/SetEventType'
import { UlEvent } from '../../types/DTO/UlEvent.dto'
import SetChapter from './Event/SetChapter'
import Stack from '@mui/material/Stack'
import ChipDate from './Event/ChipDate'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CloseIcon from '@mui/icons-material/Close'
import ModalYesNo from '../Modals/ModalYesNo'
import ModalImageGenerator from '../Modals/ModalImageGenerator'
import { resizeImage } from '../../utils/URL/urlUtils'
import HideImageIcon from '@mui/icons-material/HideImage'
import ImageIcon from '@mui/icons-material/Image'
import Tooltip from '@mui/material/Tooltip'

import '../../styles/event.css'

export default function RenderEvent (props: MailProps): JSX.Element {
  const { globalIndex, eventsDTO, setEventsDTO, showDescriptions, chapterIndex, chapterLength, notify } = props

  const dto = eventsDTO[globalIndex]

  const isCached = dto.cachedDescription !== ''

  const [localDescription, setLocalDescription] = useState(dto.description)
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false)
  const [showModalImageGenerator, setShowModalImageGenerator] = useState<boolean>(false)

  useEffect(() => { setLocalDescription(dto.description) }, [eventsDTO])

  const handleEditDescription = (e: any): void => {
    setLocalDescription(rnToBr(e.target.value))
  }

  const handleUpdateDescriptions = (): void => {
    const newEventsDTO: UlEvent[] = [...eventsDTO]
    newEventsDTO[globalIndex].description = localDescription
    setEventsDTO(newEventsDTO)
  }

  const setFetchedData = (): void => {
    const newEventsDTO: UlEvent[] = [...eventsDTO]
    newEventsDTO[globalIndex].description = eventsDTO[globalIndex].fetchedDescription
    setEventsDTO(newEventsDTO)
    setLocalDescription(eventsDTO[globalIndex].fetchedDescription)
  }

  const setCachedData = (): void => {
    const newEventsDTO: UlEvent[] = [...eventsDTO]
    newEventsDTO[globalIndex].description = eventsDTO[globalIndex].cachedDescription
    setEventsDTO(newEventsDTO)
    setLocalDescription(eventsDTO[globalIndex].cachedDescription)
  }

  const moveDown = (): void => {
    const newEventsDTO: UlEvent[] = [...eventsDTO]

    const thisEvent = eventsDTO[globalIndex]
    const nextEvent = eventsDTO[globalIndex + 1]
    newEventsDTO[globalIndex] = nextEvent
    newEventsDTO[globalIndex + 1] = thisEvent
    setEventsDTO(newEventsDTO)
  }

  const moveUp = (): void => {
    const newEventsDTO: UlEvent[] = [...eventsDTO]
    const thisEvent = eventsDTO[globalIndex]
    const prevEvent = eventsDTO[globalIndex - 1]
    newEventsDTO[globalIndex] = prevEvent
    newEventsDTO[globalIndex - 1] = thisEvent

    setEventsDTO(newEventsDTO)
  }

  const handleClickDelete = (): void => {
    setShowModalDelete(true)
  }

  const handleDeleteEvent = (): void => {
    const newEventsDTO: UlEvent[] = [...eventsDTO]
    newEventsDTO.splice(globalIndex, 1)
    setEventsDTO(newEventsDTO)
    setShowModalDelete(false)
    notify.success('Мероприятие удалено')
  }

  const handleClose = (): void => {
    setShowModalDelete(false)
    setShowModalImageGenerator(false)
  }

  return (
  <>
  <ModalYesNo
  show={showModalDelete}
  handleNo={handleClose}
  handleYes={handleDeleteEvent}
  title="Удалить мероприятие?"
  textYes="Да"
  textNo="Отмена"
/>
{showModalImageGenerator && <ModalImageGenerator
  show={showModalImageGenerator}
  setShow={handleClose}
  url={dto.url}
  title={dto.title}
  eventType={dto.eventType}
  date={ `${dto.formattedDate}${dto.timeStart && ` ${dto.timeStart}`}` }
/>
}
    <div
      id="pageBlock"
      style={{
        backgroundColor: 'rgba(52, 52, 52, 0.07)',
        padding: '1rem',
        borderTopLeftRadius: '1rem',
        borderBottomLeftRadius: '1rem',
        display: 'flex',
        flexFlow: 'column nowrap',
        marginBottom: '1rem'
      }}
    >
<div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}>
      <div
        id="blockHeader"
        style={{
          display: 'flex',
          flexFlow: 'row nowrap',
          gap: 10,
          marginBottom: 20,
          alignContent: 'top'
        }}
      >
        <div
          id="image"
          style={{
            borderRadius: 10,
            flexBasis: 50,
            minWidth: 60,
            minHeight: 60,
            width: 80,
            height: 80,
            overflow: 'hidden',
            backgroundImage: `url(${resizeImage(dto.imgUrl, 80, 80)})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        ></div>

        <div id="titleEtc" style={{}}>
          <h3>
            <a
              href={dto.url}
              style={{ color: 'black' }}
              target="_blank"
              rel="noreferrer"
            >
              {dto.title}
            </a>
          </h3>

          <div
            style={{
              display: 'flex',
              flexFlow: 'row no-wrap',
              marginBottom: 5
            }}
          >
            {dto.allDates !== undefined
              ? (
              <Stack direction="row" spacing={0} flexWrap={'wrap'}>
                {dto.allDates.map((_, chipIndex: number) => (
                  <ChipDate key={chipIndex} {...props} dateIndex={chipIndex} />
                ))}
              </Stack>
                )
              : (
              <ChipDate key={globalIndex} {...props} />
                )}
          </div>
          <div style={{ display: 'flex', flexFlow: 'row no-wrap' }}>
            <Chip
              label={`${dto.price} ₽`}
              size="small"
              style={{
                marginRight: '1rem',
                color: 'rgb(186, 74, 0)',
                border: '1px solid rgb(186, 74, 0)',
                backgroundColor: 'transparent'
              }}
            />
          </div>
        </div>
      </div>
      <div id='navButtons' style={{ display: 'flex', flexDirection: 'column', marginTop: '-50px' }}>
        <IconButton onClick={handleClickDelete} size='small'
        style={{
          backgroundColor: 'rgba(52, 52, 52, 0.07)',
          borderTopLeftRadius: '0.5rem',
          borderTopRightRadius: '0.5rem',
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
          marginBottom: '0.5rem'
        }}
        ><CloseIcon/></IconButton>
        {chapterIndex > 0 && <IconButton onClick={moveUp}><KeyboardArrowUpIcon/></IconButton>}
        {chapterIndex < chapterLength - 1 && <IconButton onClick={moveDown}><KeyboardArrowDownIcon/></IconButton>}
      </div>
      </div>

      <div id="descrAndButtons" style={{
        display: showDescriptions || !isCached ? 'flex' : 'none',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        width: '100%'
      }}>
        <div id="description" style={{
          flex: 1,
          maxWidth: 'calc(100% - 50px)',
          minWidth: 'calc(100% - 50px)',
          marginRight: '1.3rem',
          marginBottom: '1.5rem'

        }}>
          <TextareaAutosize
            id="outlined-basic"
            style={{
              maxWidth: '100%',
              minWidth: '100%',
              minHeight: 60,
              padding: '0.5rem',
              height: 100,
              fontSize: 16,
              fontFamily: 'Roboto',

              backgroundColor: isCached ? 'transparent' : 'white',
              borderWidth: 1,
              borderRightStyle: 'solid',
              borderRightWidth: isCached ? 1 : 7,
              borderRightColor: isCached ? '' : '#BA4A00',
              borderRadius: '0.5rem'
            }}
            value={brToRn(localDescription)}
            minRows={5}
            onChange={handleEditDescription}
            onBlur={handleUpdateDescriptions}
          />
          {!isCached && (
            <div style={{ color: '#BA4A00', fontSize: 12 }}>
              Описание ещё не редактировалось.
            </div>
          )}
          </div>

          <div id="buttons" style={{
            flexDirection: 'column',
            display: 'flex',
            flex: 1
          }}>

            <IconButton
              size="small"
              color='info'
              onClick={setFetchedData}
              style={{ marginRight: 10 }}
            >
              <CloudDownloadIcon />
            </IconButton>
            <IconButton
              size="small"
              disabled={!isCached}
              onClick={setCachedData}
              color='info'
              style={{ marginRight: 10 }}
            >
              <UploadFileIcon />
            </IconButton>
          </div>

      </div>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          justifyContent: 'space-between'
        }}
      >
        <SetEventType {...props} />
        <SetChapter {...props} />
        <PremiereCheckBox {...props} />
        {dto.hasImagesIndex
          ? <Tooltip title='Сгененрировать картинки'>
          <IconButton
            color='info'
            onClick={() => { setShowModalImageGenerator(true) }}><ImageIcon/></IconButton>
            </Tooltip>
          : <Tooltip title='Показать имя директории в консоли'><IconButton
          onClick={() => { console.log(dto.imgsPath) }}
          ><HideImageIcon/></IconButton>
          </Tooltip>
        }

      </div>
    </div></>
  )
}

import React, { CSSProperties } from 'react'
import useFitText from 'use-fit-text'

interface DymanicSizeTextProps {
  text: string
  maxFontSize?: number
  minFontSize?: number
  style: CSSProperties
}

export const DymanicSizeText = (props: DymanicSizeTextProps): JSX.Element => {
  const { text, style, maxFontSize = 100, minFontSize = 1 } = props
  const { fontSize, ref } = useFitText({ minFontSize, maxFontSize, resolution: 1 })

  return <div
    ref={ref}
    className="title"
    style={{
      fontSize,
      ...style
    }}>{text}</div>
}

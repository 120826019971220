import THUMB_REGULAR from '../assets/certs/thumbs/thumb_regular.jpg'
import THUMB_NEWYEAR from '../assets/certs/thumbs/thumb_newyear.jpg'
import THUMB_BIRTHDAY from '../assets/certs/thumbs/thumb_birthday.jpg'

import TPL_REGULAR from '../fileTemplates/certs/REGULAR'
import TPL_BIRTHDAY from '../fileTemplates/certs/BIRTHDAY'
import TPL_NEWYEAR from '../fileTemplates/certs/NEWYEAR'
import { CertTemplatateProps } from '../Components/Modals/ModalCertGenerator'

interface CertTemplateParams {
  thumb: string
  template: (args: CertTemplatateProps) => JSX.Element
  outputName: string
}

type MapParams = Record<string, CertTemplateParams>

export const mapKeyToTemplate: MapParams = {
  REGULAR: {
    thumb: THUMB_REGULAR,
    template: TPL_REGULAR,
    outputName: 'сертификат.jpg'
  },
  BIRTHDAY: {
    thumb: THUMB_BIRTHDAY,
    template: TPL_BIRTHDAY,
    outputName: 'сертификат-день-рождения.jpg'
  },
  NEWYEAR: {
    thumb: THUMB_NEWYEAR,
    template: TPL_NEWYEAR,
    outputName: 'сертификат-новый-год.jpg'
  }

}

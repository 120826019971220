import React, { CSSProperties } from 'react'
import { CertTemplatateProps } from '../../Components/Modals/ModalCertGenerator'
import useFitText from 'use-fit-text'
import './style.css'
import { DymanicSizeText } from './DynamicSizeText'

export default function BIRTHDAY ({
  name,
  date,
  amountRub,
  num,
  message
}: CertTemplatateProps): JSX.Element {
  return (
    <div className="templateMainContainer">
      <div
        className="bgrImage"
        style={{
          backgroundImage: 'url(certs/template_birthday.png)',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'block'
        }}
      >
        {message && (
          <div
            style={{
              marginTop: '20px',
              height: '250px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '0 17%'
            }}
          >
            <DymanicSizeText
              text={message}
              style={{
                // border: '1px red solid',
                minHeight: '95%',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                textAlign: 'center',
                alignItems: 'center',
                lineHeight: 1.1,
                fontFamily: 'Caveat',

                color: '#6545ad'
              }}
            />
          </div>
        )}

        <div
          style={{
            // border: '2px solid green',
            marginTop: message ? '125px' : '400px',
            height: '80px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '0 5%'
          }}
        >
          <DymanicSizeText
            text={`№ ${num} от ${date}`}
            style={{
              // border: '1px red solid',
              minHeight: '100%',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              textAlign: 'center',
              alignItems: 'center',
              lineHeight: 1.1,
              fontFamily: 'Podkova',
              color: '#000'
            }}
          />
        </div>
        <div
          style={{
            // border: '2px solid blue',
            marginTop: '-10px',
            height: '180px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '0 5%'
          }}
        >
          <DymanicSizeText
            text={`Для ${name}`}
            style={{
              marginTop: -30,
              // border: '1px red solid',
              minHeight: '100%',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              textAlign: 'center',
              alignItems: 'center',
              lineHeight: 1.1,
              fontFamily: 'Playfair',
              color: '#f2ab36'
            }}
          />
        </div>

        <div
          style={{
            height: '400px',
            marginTop: 10,
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            textAlign: 'center',
            alignItems: 'center',
            lineHeight: 1,
            fontFamily: 'Podkova',
            fontSize: 75,
            color: '#000'
          }}
        >
          на посещение любых мероприятий, экскурсий
          <br /> и вебинаров лектория «Умные Люди»
          <br />
          номиналом <span style={{ color: '#f2ab36' }}>{amountRub}</span> рублей
        </div>
      </div>
    </div>
  )
}

import React, { CSSProperties } from 'react'
import { CertTemplatateProps } from '../../Components/Modals/ModalCertGenerator'
import './style.css'
import { DymanicSizeText } from './DynamicSizeText'

export default function REGULAR ({ name, date, amountRub, num, message }: CertTemplatateProps): JSX.Element {
  return (
    <div className="templateMainContainer">
      <div
        className="bgrImage"
        style={{
          backgroundImage: 'url(certs/template_regular.png)',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'block'
        }}
      >

{message && (
          <div
            style={{
              marginTop: '20px',
              height: '250px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '0 18%'
            }}
          >
            <DymanicSizeText
            maxFontSize={65}
              text={message}
              style={{
                // border: '1px red solid',
                minHeight: '95%',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                textAlign: 'center',
                alignItems: 'center',
                lineHeight: 1,
                fontFamily: 'Podkova',
                fontWeight: 'black',
                color: '#000'
              }}
            />
          </div>
)}

        <div style={{
          marginTop: message ? '160px' : '430px',

          height: '80px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '0 5%'
        }}>
        <DymanicSizeText
        text={`№ ${num} от ${date}`}
        style={{
          minHeight: '100%',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          textAlign: 'center',
          alignItems: 'center',
          lineHeight: 1.1,
          fontFamily: 'Podkova',
          color: '#000'
        }}
      />
      </div>
        <div style={{
          marginTop: '-10px',
          height: '180px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '0 5%'
        }}>
        <DymanicSizeText
        text={`Для ${name}`}
        style={{
          marginTop: -30,
          minHeight: '100%',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          textAlign: 'center',
          alignItems: 'center',
          lineHeight: 1.1,
          fontFamily: 'Playfair',
          color: '#f2ab36'
        }}
      />
      </div>

        <div style={{
          height: '400px',
          marginTop: 10,
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          textAlign: 'center',
          alignItems: 'center',
          lineHeight: 1,
          fontFamily: 'Podkova',
          fontSize: 75,
          color: '#000'
        }}>
        на посещение любых мероприятий, экскурсий<br/> и вебинаров лектория «Умные Люди»<br/>номиналом <span style={{ color: '#f2ab36' }}>{amountRub}</span> рублей
      </div>
      </div>
    </div>
  )
}

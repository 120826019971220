import React, { useRef, useState } from 'react'
import Modal from '@mui/material/Modal'
import * as htmlToImage from 'html-to-image'
import Box from '@mui/material/Box'

import { mapKeyToTemplate } from '../../params/certTemplates'
import { Button } from '@mui/material'

export interface CertTemplatateProps {
  name: string
  date: string
  num: string
  amountRub: string
  message?: string
}

interface ModalCertGenProps {
  template: string
  show: boolean
  setShow: (arg: string) => void
}

const ModalCertGenerator = ({
  template,
  show,
  setShow
}: ModalCertGenProps): JSX.Element => {
  const domEl = useRef(null)

  const previewEl = useRef<HTMLDivElement>(null)

  const [message, setMessage] = useState(
    template === 'BIRTHDAY' ? 'C Днём Рождения!' : ''
  )
  const [num, setNum] = useState('###')
  const [date, setDate] = useState('##.##.####')
  const [name, setName] = useState('###### #########')
  const [amountRub, setAmountRub] = useState('#####')

  const CurrentTemplate = mapKeyToTemplate[template].template

  const downloadImage = async (): Promise<void> => {
    if (!domEl.current) {
      console.error('DOM element is not ready')
      return
    }

    const convertparams = {
      cacheBust: true,
      quality: 0.9,
      canvasHeight: 1414,
      canvasWidth: 2000,
      width: 2000,
      height: 1414,
      pixelRatio: 1
    }

    try {
      let dataUrl = await htmlToImage.toJpeg(domEl.current, convertparams)

      console.log(dataUrl.length)
      for (let i = 0; i < 10; i++) {
        // When a blank background appears, the image size will be much smaller than before. Find a value that can determine whether the generation was successful
        if (dataUrl.length / 1024 > 300) {
          break
        } else {
          dataUrl = await htmlToImage.toJpeg(domEl.current, convertparams)
        }
      }

      if (!dataUrl) {
        console.error('Failed to generate image')
        return
      }
      const link = document.createElement('a')
      link.download = mapKeyToTemplate[template].outputName
      link.href = dataUrl
      link.click()
    } catch (error) {
      console.error('Error generating image:', error)
    }
  }

  return (
    <Modal
      onClose={() => {
        setShow('')
      }}
      open={show}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        width: '100%',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'rgba(255,255,255,0.7)',
        zIndex: 9999
      }}
    >
      <Box
        sx={{
          width: 340,
          height: 550,
          backgroundColor: '#fff',
          padding: 2,
          borderRadius: 1,
          display: 'block'
        }}
      >
        <div
          ref={previewEl}
          style={{
            // border: '2px solid red',
            width: '2000px',
            height: '1414px',
            transformOrigin: 'left top',
            scale: '0.17',
            imageRendering: 'crisp-edges'
          }}
        >
          <div
            style={{ width: 2000, height: 1414, backgroundColor: '#ffffff' }}
            ref={domEl}
          >
            <CurrentTemplate
              amountRub={amountRub}
              date={date}
              name={name}
              num={num}
              message={message}
            />
          </div>
        </div>
        <div
          style={{
            // border: '2px solid red',
            marginTop: -1160,
            marginLeft: -15,
            // marginLeft: 510,
            fontSize: 16
          }}
        >
          {(template === 'BIRTHDAY' || template === 'REGULAR') && (
            <div style={{ margin: 15 }}>
              <textarea
                rows={3}
                placeholder={
                  template === 'BIRTHDAY'
                    ? 'С Днём Рождения!'
                    : 'Комментарий для шапки'
                }
                style={{ fontSize: 16, padding: 5, resize: 'none', width: 300 }}
                onChange={(e) => {
                  setMessage(e.target.value)
                }}
              />
            </div>
          )}
          <div style={{ margin: 15 }}>
            №{' '}
            <input
              type="number"
              style={{ width: '50px', fontSize: 16, padding: 5 }}
              placeholder="554"
              onChange={(e) => {
                setNum(e.target.value)
              }}
              required
            />{' '}
            от{' '}
            <input
              type="date"
              style={{ width: '120px', fontSize: 16, padding: 5 }}
              placeholder="11.12.2025"
              required
              onChange={(e) => {
                const arr = e.target.value.split('-')
                setDate(`${arr[2]}.${arr[1]}.${arr[0]}`)
              }}
              pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
            />
          </div>

          <div style={{ margin: 15 }}>
            Для{' '}
            <input
              type="text"
              placeholder="Иванова Петра"
              style={{ fontSize: 16, padding: 5 }}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
          </div>
          <div style={{ margin: 15 }}>
            на сумму{' '}
            <input
              style={{ width: '70px', fontSize: 16, padding: 5 }}
              type="number"
              placeholder="3000"
              required
              onChange={(e) => {
                setAmountRub(e.target.value)
              }}
            ></input>{' '}
            рублей
          </div>
        </div>
        <Button
          variant="contained"
          size="small"
          sx={{ marginTop: 2, marginLeft: 0 }}
          onClick={downloadImage}
        >
          Скачать JPG
        </Button>
      </Box>
    </Modal>
  )
}

export default ModalCertGenerator
